.sixty-plus-years {
  background-color: #000000;

  .container {
    display: flex;
    // max-width: 1440px;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    height: 508px;

    @media (max-width: 768px) {
      flex-direction: column;
      min-height: 570px;
    }

    @media (max-width: 375px) {
      min-height: 614px;
    }
  }

  .text-box {
    padding-left: 140px;
    width: 50%;
    padding-right: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media(max-width:768px){
      padding-left: 36px;
      padding-right: 36px;
      padding-top: 30px;
      width: 100%;
    }

    .title {
      /*margin-top: 245px;*/
      margin-bottom: 7px;
      font-size: 45px;
      line-height: 70px;
      text-align: left;
      font-family: 'Cormorant Garamond';
      color: #ffffff;
      width: 494px;

      @media (max-width: 1279px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        margin-top: 93px;
        font-size: 34px;
        line-height: 44px;
        text-align: center;
        margin-bottom: 12px;
      }

      @media (max-width: 560px) {
        font-size:30px;
        margin-top:0px;      
      }
    }

    .description {
      margin-bottom: 66px;
      font-size: 14px;
      line-height: 28px;
      font-family: 'Inter';
      font-weight: 300;
      color: #ffffff;
      width: 450px;

      @media (max-width: 1279px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 42px;
        text-align: center;
      }
    }

    .transparent-btn {
      display: flex;
      padding-top: 14px;
      padding-bottom: 13px;
      width: 200px;
      height: 44px;
      border: 2px solid #7990a9;
      font-size: 14px;
      font-weight: bold;
      line-height: 38px;
      font-family: 'Inter';
      text-align: center;
      letter-spacing: 1.54px;
      color: #7990a9;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      /*margin-bottom: 20px;*/
      text-transform: uppercase;
      background: linear-gradient(
        120deg,
        #7990a9 50%,
        rgba(255, 255, 255, 0) 50%
      );
      transition: all 0.6s ease-out;
      background-size: 250%;
      background-position-x: 100%;
      background-repeat: no-repeat;

      &:hover {
        color: white;
        background-position-x: 0%;
      }

      @media (max-width: 768px) {
        margin-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media(max-width: 768px) {
      margin-bottom: 84px;
    }
  }

  .photo-box {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;

    @media (max-width: 768px) {
      height: 450px;
      width: 100%;
    }

    @media (max-width: 560px) {
      height: 230px;
      background-size: cover;
      background-position: inherit; 
    }
  }
}
